Promise.all(
  Array.from(document.images)
    .filter((img) => !img.complete)
    .map(
      (img) =>
        new Promise((resolve) => {
          img.onload = img.onerror = resolve;
        })
    )
).then(() => {
  const el: any = document.querySelector(".background")!;
  el.style.opacity = 1;
});
